table {
  width: 100%;

  thead th {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: $gray-700;
    text-transform: uppercase;
    padding: 1rem 0;
  }

  tbody {
    tr {
      &:hover {
        background-color: #e9e9e9;
      }
    }
  }

  tr:first-of-type td {
    border-top: none;
  }

  td {
    border: 0px;
    border-top: 1px solid $gray-300;
    font-weight: 400;
    color: $gray-900;
    font-size: 14px;
    padding: 1rem 0 1rem 2rem;
    cursor: pointer;
  }

  .label {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 5px 6px 4px 7px;
    border-radius: 2px;
    background-color: $blue-400;
  }
}

.simple-table {
  width: 100%;
  display: grid;
  row-gap: 1rem;

  .link {
    display: contents;
    text-decoration: none;
    color: inherit;
  }

  .cell {
    padding: 0.75rem;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .deleted {
    background-color: grey;
  }
}

.simple-table > div > .cell {
  padding-bottom: 0;
  padding-top: 0;
}

.simple-table > div > .cell:first-child {
  padding-left: 0;
}

.simple-table > div > .cell:last-child {
  justify-content: flex-end;
  padding-right: 0;
}

.simple-table > a > .cell:first-child {
  padding-left: 1.5rem;
}

.simple-table > a > .cell:last-child {
  justify-content: flex-end;
}

.header-box {
  align-items: center;
  display: flex;
}

.icon-box {
  width: 14px;
  height: 13px;
  margin: 2px 6px 4px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.text-box {
  font-size: 14px;
  font-weight: 200;
}

.permissions-table {
  overflow-x: scroll;
  margin-bottom: 3rem;
  td {
    background: #fff;
    border-right: solid 1px $blue-500;
    padding: 1rem;
    &:last-child {
      border-right: none;
    }
    &.td-space {
      border-right: solid 1rem $blue-100;
      width: 260px;
      border-top: none;
    }
  }
  .permission-thead-menu {
    background: $blue-100;
    text-transform: uppercase;
    color: $blue-700;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.92px;
    padding: 0.5rem 0;
    grid-template-columns: 1fr auto;
    align-items: baseline;
    text-align: right;
    .btn-circular {
      margin-left: 0.5rem;
    }
    .permission-thead-menu-text,
    .btn-circular {
      display: inline-block;
    }
  }
  thead td {
    border-top: none;
  }
  tbody td {
    border-top: solid 1px $blue-500;
    text-align: center;
    > .fa-eye-slash {
      color: $gray-700;
    }
    > .fa-comment-alt {
      color: $blue-700;
    }
    &:first-child {
      text-align: left;
    }
  }
  &.project-overview-users tbody td {
    text-align: left;
  }
}

.selected-permission-icon[aria-expanded='true'],
.selected-permission-icon:hover {
  padding: 0.5rem;
  margin: -1rem;
  background: $blue-300;
  border-radius: 8px;
  &:after {
    content: '\f078';
    font-size: 10px;
    padding-left: 5px;
    font-weight: 600;
  }
}

.responsive-table {
  overflow-x: auto;
}

.table--reference-data {
  table {
    td {
      border: none;
    }
  }
}

.page-content {
  table {
    table-layout: auto;

    td {
      border: 0;
    }
  }

  .data-grid-container {
    .data-grid {
      table-layout: auto;

      td {
        border: 0;
      }

      .cell {
        &.read-only {
          background: transparent;
          color: #252525;
          text-align: left;
        }

        &.table-- {
          &border-top {
            border-top: 1px solid black;
          }

          &border-bottom {
            border-bottom: 1px solid black;
          }
          &border-left {
            border-left: 1px solid black;
          }
          &border-right {
            border-right: 1px solid black;
          }
          &border-top-bottom {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
          }
          &border-left-right {
            border-left: 1px solid black;
            border-right: 1px solid black;
          }
          &border-all {
            border: 1px solid black;
          }

          &background- {
            &primary {
              background: $blue-300;
            }
            &secondary {
              background: $blue-500;
            }
            &tertiary {
              background: $blue-700;
            }
            &quaternary {
              background: #edeff7;
            }
          }
        }
      }
    }
  }
}
