.badge {
  margin: 5px 10px 5px 10px;
  padding: 7px 11px 6px;
  border-radius: 3px;
  background-color: #d8d8d8;
}

.profiles {
  .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 0 0 1rem;
    border-radius: 50%;
    background-color: #d8d8d8;
  }
}

.editor-container {
  position: relative;
  z-index: 10;
  .section-name {
    position: absolute;
    top: 30px;
    left: 0;
  }
}

.document-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.editor-shadow-host {
  position: relative;
  z-index: -10;
}

.sidebar {
  position: fixed;
  top: $navbarHeight;
  right: 0;
  min-width: $sidebarWidth;
  height: $sidebarHeight;
  background-color: #ffffff;
  border-left: solid 1px #ededed;
  box-sizing: border-box;
  z-index: 12;

  transition: all 300ms ease-in-out;
  .sidebar-arrow {
    width: 42px;
    height: 30px;
    margin: 0 1px 6px 34px;
    padding: 9px 7px 8px 11px;
    border-radius: 100px;
    background-color: #ffffff;
  }
  &.closed {
    right: -$sidebarWidth;

    .controls {
      .sidebar-arrow {
        transform: rotate(180deg);
        padding: 9px 7px 8px 20px;
      }
    }
  }

  &-top {
    display: flex;
    align-items: center;
    padding: 18px;

    &-item {
      margin-right: 1rem;
    }
  }

  .controls {
    position: absolute;
    top: 4px;
    left: -32px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    svg {
      transition: all 300ms ease-in-out;
    }
  }

  .content--container {
    display: grid;
    grid-template-columns: [content] auto [menu] $sidebarMenuWidth;
    box-sizing: border-box;
    height: 100%;

    .menu {
      display: flex;
      flex-flow: column wrap;
      width: $sidebarMenuWidth;
      border-left: 1px solid #ededed;
      padding: $normalDistance 0;
      box-sizing: border-box;

      button {
        width: 40px;
        height: 40px;
      }
    }

    .content {
      box-sizing: border-box;
      max-width: 343px;
      overflow-y: scroll;
    }
  }

  .preview--container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;

    .item {
      transition: all 100ms ease-in-out;
      background-color: transparent;
      cursor: pointer;
      padding: $spacer $spacer / 2;

      &.active {
        background-color: $blue-500;
      }
    }

    .preview {
      width: 124px;
      height: 176px;
      background: $gray-300;
      border: solid 1px darken($gray-300, 10%);
      text-align: center;
      color: white;
      font-size: 3em;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      svg {
        align-self: center;
      }
    }

    .page-preview-menu {
      &-wrapper {
        margin-top: $normalDistance;
        color: $dark;
        text-align: left;
        align-self: flex-start;
        i {
          padding-left: 5px;
          font-size: 14px;
          width: 30px;
        }
        div:not(.text-disabled) {
          cursor: pointer;
        }
      }
      &-title {
        font-size: 13px;
      }
    }
  }

  .action-bar {
    flex: auto;
    flex-grow: 0;
    margin-bottom: $normalDistance;

    .button {
      margin-top: $normalDistance/2;
    }
  }

  .item:not(.active):hover {
    background-color: $blue-100 !important;
  }
}

.layout-picker {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: $normalDistance;
  column-gap: $smallDistance;
  row-gap: $smallDistance;
  border-radius: 10px;
  border: solid 1px rgb(213, 213, 213);
  background-color: #ffffff;
  overflow-y: scroll;

  &:hover {
    cursor: default;
  }
}

.layout-picker-layout {
  width: 114px;
  height: 141px;
  border-radius: 10px;
  border: solid 1px #d1d1d1;
  background-color: #f7f7f7;
  color: $gray-900;
  font-size: small;

  &:hover {
    cursor: pointer;
  }
}

.layout-picker-label {
  text-align: center;
}

.layout-picker-image {
  width: 52px;
  height: 53px;
  font-family: FontAwesome5Pro;
  margin: 40px auto 0;
  font-size: 52px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c0c0c0;
}

.select-content {
  width: inherit;
  height: 400px;

  border-radius: 8px;
  border: dashed 2px #d4d4d4;
  background-color: #f3f3f3;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  div {
    width: 150px;
    height: 200px;
    background-color: white;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $gray-700;

    svg {
      font-size: 3rem;
    }

    &:hover {
      background-color: darken(white, 10%);
    }
  }
}

.template-editor {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: $normalDistance;
  z-index: 1;

  .layout {
    border: 1px $gray-700 dashed;
  }
  .template-toolbar {
    position: absolute;
    left: 850px;
    align-items: center;
    justify-content: center;
    top: 7rem;
    width: 800px;

    input {
      width: 300px;
    }

    & > div {
      & > div {
        width: 400px;
        z-index: 100;
      }
    }
    textarea {
      font-size: 13px;
      font-family: monospace;
      line-height: normal;
    }
  }
}

.reference {
  padding: 3px 3px 2px;
  margin: 0 1px;
  vertical-align: baseline;
  display: inline-block;
  border-radius: 4px;
  background-color: $blue-900;
  color: $white;
  font-size: 0.9em;
  cursor: pointer;
  position: relative;
  transition: all 150ms linear;
}
.toolbar {
  position: absolute;
  top: 0;
  left: 50px;
  padding: 0.25rem 0;
  background-color: $blue-200;
  border: 1px solid $blue-500;
  border-radius: 4px;
  color: $body-color;
  display: flex;
  flex-direction: row;
  z-index: 2;

  select {
    margin-right: $normalDistance;
    border: none;
    padding: $smallDistance;
    background-color: transparent;
  }

  .divider {
    width: 1px;
    height: inherit;
    background-color: $blue-500;
  }

  button {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    margin: 0 $smallDistance;
  }
}

.sub-toolbar {
  display: flex;
  flex-direction: row;

  .rw-widget-input {
    width: 190px;
    height: 32px;
  }

  .divider {
    width: 1px;
    height: inherit;
    margin: 6px 0px;
    border: solid 1px $blue-500;
  }

  .toolbar-btn {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
    margin: 0 $smallDistance;
    color: $gray-900;

    &:hover {
      background-color: $blue-300;
      border-radius: 5px;
    }

    &:active,
    &.active {
      background-color: $blue-500;
      border-radius: 5px;
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  .force-text {
    margin: 0;
  }
}
.default-editor-element {
  border: 1px dashed red;
}

.side-by-side {
  position: relative;
  display: grid;
  grid-template-columns: auto auto 1fr;
  z-index: 1;
  grid-gap: 4rem;

  .top {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    > div {
      margin: 0 15px 0 20px;
    }
    select {
      width: 100%;
      min-width: 15ch;
      max-width: 30ch;
      border: 0;
      border-radius: 0.25em;
      font-size: 14px;
      cursor: pointer;
      background-color: $blue-100;
    }
  }
}

.sidebar-filter {
  width: 315px;
  height: 289px;
  margin: 7px 13px 5px 15px;
  padding: 57px 16px 60px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(96, 109, 123, 0.09);
  border: solid 1px var(--background);
  background-color: #fff;
}

.side-by-side-version-selector {
  display: flex;
}

.revision-selector {
  display: flex;
  flex-flow: column wrap;
  background: #ffffff;
  width: 260px;

  .element {
    display: flex;
    flex-flow: column wrap;
    border-radius: 8px;
    //border: solid 1px #e6e6e6;
    margin: 12px;
  }

  .named-versions {
    width: 160px;
    height: 19px;
    margin: 0 0 5px;
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: 1px;
    color: $gray-700;
  }

  button {
    &:hover {
      background-color: darken(#fff, 10%);
    }
  }
}
.version-selector {
  display: flex;
  .column {
    width: 50px;
    flex: 50%;
    margin: 10px;
  }
}
.version-box {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 32px;
  margin: 0 6px 4px 16px;
  padding: 8px 8px 7px;
  border-radius: 5px;
  border: solid 1px var(--line);
  background-color: #fff;
}

.line {
  width: 235px;
  height: 1px;
  margin: 94px 0 201px;
  border: solid 1px var(--line);
}

.template-style-error-message {
  color: red;
}

.document-insert-dropdown {
  width: 107px;
  padding: 0;
  align-items: center;
  grid-column-gap: 5px;
  border: solid 1px $gray-300;
  font-size: 15px;
  color: $gray-900;
  border-radius: 5px;
  &:hover,
  &:focus {
    border: solid 1px $gray-300;
  }
  i {
    font-size: 10px;
  }
  .rounded-circle {
    background: $blue-400;
    color: $blue-700;
    i {
      font-size: 7px;
    }
  }
}

.document-insert-list {
  width: 145px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 10px 0 rgba(96, 109, 123, 0.09);
  z-index: 999999;
  border: solid 1px $gray-300;
}

.read-only-mark {
  width: 35px;
  height: 35px;
  margin-top: 100px;
  margin-left: -17px;
  border-radius: 50%;
  background: $blue-400;
  color: $blue-700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-image--container,
.editor-table--container {
  position: relative;

  .controls--container {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 5px;
    right: 5px;
    background: #f1f5f9;
    border-radius: 4px;
    box-shadow: 1px 1px 5px black;
    transition: all 100ms ease-in-out;
    opacity: 0;
    padding: 2px;
    z-index: 5;

    button {
      background: transparent;
      height: 30px;
      width: 30px;
      border: 0;
      cursor: pointer;
    }
  }

  &:hover {
    .controls--container {
      opacity: 1;
    }
  }
}
