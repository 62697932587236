.root {
  height: 100vh;
}

pre {
  padding: 10px;
  background-color: #eee;
  white-space: pre-wrap;
}

.date-text {
  font-size: 12px;
  color: $gray-700;
  font-weight: 400;
}

:not(pre) > code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
}

img {
  max-width: 100%;
  max-height: 200px;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

blockquote[dir='rtl'] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}

[data-slate-editor] > * + * {
  margin-top: 1em;
}

.page-wrapper {
  margin: 0 auto;
}

.page-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 40px;
  color: white;
  text-align: center;
  margin-top: 10px;
  font-size: 2em;

  &--button {
    height: 20px;
    margin: 5px;
    padding: 2px 6px 2px 7px;
    border-radius: 3px;
    text-align: center;
    align-items: center;
    border: 0;
    background-color: $blue-300;
    font-family: Manrope, serif;
    font-size: 12px;
    color: $blue-700;
  }

  .page-divider-section-name {
    float: left;
    font-size: 0.95rem;
    color: black;
    position: absolute;
    left: 0;
  }
}

.area-content {
  grid-area: content;
  position: relative;
}

.layout-one {
  display: grid;
  grid-template-rows: 300px auto;
  row-gap: 20px;

  :nth-child(2) {
    columns: 2;
    column-fill: auto;
    height: 100%;
    text-align: justify;
  }
}

.add-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $red-500;
  color: #eeeeee;
  font-weight: bolder;
  font-size: 35px;
  text-align: center;
  margin: 5px auto;
}

.container {
  width: 1366px;
  height: 100vh;
  flex: auto;
  flex-grow: 1;
  flex-flow: column;
  align-content: center;
  align-self: center;
  margin: 0 auto;
}

.add-new-popup {
  height: 266px;
  width: 300px;
  padding-top: 24px;
  background: #fff;
  min-width: 200;
  box-shadow: 0 0 10px 0 rgba(96, 109, 123, 0.09);
  border-radius: 5px;
  border: solid 1px #f6f8fa;
  text-align: center;

  &.wider-popup {
    position: absolute;
    right: 0;
    z-index: 1;
    height: 87px;
    padding-top: 0;
    width: 369px;

    input {
      margin: 1rem;
      border: 1px solid $gray-300;
      border-radius: 5px;

      &:focus {
        border: solid 1px $blue-800;
      }
    }

    &.popup-with-button {
      height: 130px;

      button {
        width: 328px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
        font-weight: bold;
        padding: 0.5rem 2rem;
      }
    }
  }
}

.grid--container {
  display: grid;

  .full--height {
    height: 100vh;
  }

  &.columns {
    grid-template-columns: [gutter-left] 15vw [content] auto [gutter-right] 15vw;

    @media (min-width: 1858px) {
      grid-template-columns: [gutter-left] auto [content] 1280px [gutter-right] auto;
    }
  }

  .content {
    grid-column-start: content;
    grid-column-end: content;
  }
}

.menu--layout {
  margin-top: 27px;
  margin-bottom: 40px;
  grid-template-columns: repeat(6, 1fr);
  font-size: 14px;
  font-weight: 450;

  & > a {
    display: inline-block;
    border-bottom: 2px solid transparent;
    width: fit-content;
    padding: 7px;
    color: $gray-900;

    &:not(:first-child) {
      margin-left: 44px;
    }

    &.active {
      border-color: $primary;
      color: $dark;
    }
  }
}

.organization-name {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.projects {
  .order-by-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 4px;

    .icon-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 4px;
    }
  }

  .check-input {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  .document-import-wrapper {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
}

.project--details {
  min-height: 100%;

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;

    .button-wrapper {
      align-self: end;
    }

    .button {
      font-size: 12px;
      font-weight: bold;
      align-self: center;
    }
  }

  .project-details-wrapper {
    margin-bottom: 6rem;
  }

  .company-header {
    position: absolute;
    top: 50px;
    font-size: 12px;
    font-weight: 600;
    color: $gray-700;
    text-transform: uppercase;
  }

  .space-box-container {
    display: flex;
    align-self: center;
    justify-content: space-between;
  }

  .reference-data--layout {
    grid-template-columns: [data-list] 25% auto;
  }

  .grid-button-flow {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $normalDistance;

    > span {
      align-self: center;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .content {
    grid-column-start: content;
    grid-column-end: content;
    position: relative;
  }

  .two-line-overflow {
    overflow: hidden;
    line-height: 1.2rem;
    max-height: 2.4rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }

  .reference-data--list {
    display: flex;
    flex-direction: column;
    height: 643px;
    overflow: auto;

    &-item {
      text-decoration: none;
      padding: 1rem 2.4rem;
      box-sizing: border-box;
      align-self: center;
      display: grid;
      grid-template-columns: auto 1fr auto auto;

      > a {
        text-decoration: none;
        color: $gray-900;

        &:visited,
        &:active {
          color: $gray-900;
        }
      }

      &:not(.disabled) {
        .active {
          background-color: #ffffff;
        }

        &:hover {
          background-color: darken($gray-300, 10%);
        }
      }

      .toggle-icon {
        padding: 5px 10px 0 0;
        display: none;

        &.drag-drop {
          color: $blue-400;
          font-size: 20px;
        }

        &.more {
          padding-top: 0.5rem;
        }
      }

      .not-in-doc-icon {
        padding: 5px;
        background: $blue-300;
        color: #7694b2;
        border-radius: 3px;
        height: 24px;
        width: 24px;
        font-size: 12px;
        margin: 5px 12px 0 3px;
      }
    }

    a.active .reference-data--list-item {
      padding: $normalDistance;

      > div {
        overflow: hidden;
      }

      .toggle-icon {
        display: block !important;
      }
    }
  }
}

.box {
  padding: $normalDistance;
  margin-bottom: 1rem;

  &.white {
    background-color: white;
  }
}

.button--small {
  padding: 3px 5px;
}

.label-template-scope {
  padding-left: 2rem;
  color: $gray-700;
}

.overview--list {
  font-size: 14px;

  .list--item {
    display: grid;
    height: 56px;
    background-color: #fff;
    transition: all 50ms linear;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1rem;
    align-items: center;

    &.section {
      cursor: pointer;
    }

    &.disabled--item {
      background-color: darken(#fff, 7%);
      opacity: 0.3;
    }

    &:not(.no-hover):hover {
      background-color: darken(#fff, 10%);
    }

    > div {
      padding-right: 1rem;
      display: flex;
      align-self: center;
    }
  }

  &.version {
    .list--item {
      grid-template-columns: 1fr auto auto;
    }

    .selected-version {
      background-color: $blue-500;
    }
  }

  &.section {
    .list--item {
      grid-template-columns: auto 1fr repeat(3, auto);
    }
  }

  &.templates {
    .list--item {
      grid-template-columns: 1fr repeat(3, auto);
    }
  }
}

.user--circle {
  border-radius: 50%;
  font-size: 13px;
  font-weight: 500;
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: $blue-400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.user--name {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  margin-left: 12px;
}

.project--overview {
  .action--align--left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.project--loading--container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.project--loading--modal {
  width: 500px;
  height: 200px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255);
  z-index: 10;
}

.box {
  border: 1px solid #a2a2a2;
  background-color: $white;
  padding: $normalDistance;
  border-radius: 5px;

  &.error {
    border-color: #b22222;
    background-color: lighten(#b22222, 20%);
  }
}

.list {
  display: flex;
  flex-direction: column;

  .list--item {
    background-color: #ffffff;
    padding: $smallDistance $normalDistance;
    transition: all 50ms linear;

    &:hover {
      background-color: darken($white, 15%);
    }
  }
}

.select--cell {
  display: grid;
  grid-template-rows: 1fr auto;
}

.image--modal {
  padding: $normalDistance;
}

.image--list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .image {
    display: flex;
    flex-direction: column;
    margin: $smallDistance;
    cursor: pointer;

    img {
      width: 164px;
      height: 186px;
      margin: 0 0 10px;
      padding: $normalDistance;
      border-radius: 10px;
      border: solid 1px #d1d1d1;
      background-color: #f7f7f7;
    }

    &:hover {
      img {
        background-color: darken(#f7f7f7, 5%);
      }
    }

    span {
      text-align: center;
      width: 200px;
    }
  }
}

.picture {
  border: solid 1px $gray-400;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: white;

  .img-wrapper img {
    height: 169px;
  }

  .file-name {
    height: 40px;
    padding: 10px 15px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.picture.active {
  border: 2px solid #4db894;
}

.overview--image--content {
  table {
    td,
    th {
      padding: 0.25em 0.5em;
    }

    td.image-cell {
      width: 300px;
      max-height: 50px;
    }
  }
}

.img-wrapper img {
  width: 100%;
  object-fit: cover;
}

.image--holder {
  height: 400px;
  overflow: hidden;
}

.image--container {
  position: relative;

  .controls {
    &--zoom {
      position: absolute;
      top: $smallDistance;
      left: $smallDistance;
      display: flex;
      flex-direction: column;

      button {
        border: 1px solid $gray-800;
        background-color: $white;
        width: 30px;
        height: 30px;
        font-size: 16px;
        outline: none;
        cursor: pointer;

        &:active {
          background-color: darken($white, 15%);
        }
      }
    }
  }
}

.overview--image {
  display: grid;
  grid-template-rows: [menu] auto [content] 1fr;
  padding-bottom: 2rem;

  &--menu {
    height: 40px;
    margin: $normalDistance 0;
    display: grid;
    align-items: center;
    column-gap: $normalDistance;
  }
}

.overview--image--menu {
  grid-template-columns: 1fr auto auto auto;
}

.overview--reference--menu {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  align-items: baseline;
  justify-items: end;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  .button:first-child {
    margin-right: 1rem;
  }

  .button:nth-child(2) {
    margin-right: 1rem;
  }

  .button:nth-child(3) {
    margin-right: 1rem;
  }
}

.overview--image--list--type {
  justify-self: end;
}

.divider {
  &.vertical {
    width: 1px;
    height: 100%;
    background-color: $gray-400;
  }

  &.horizontal {
    width: 100%;
    height: 1px;
    background-color: $gray-800;
  }
}

.permission-title {
  font-weight: 600;
  margin-top: 0;
}

.permission-section-header {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: baseline;
  margin: 3rem 0 0.5rem 0;

  &.head-w-new-group-btn {
    grid-template-columns: 1fr auto auto;
  }

  .edit-permission-group {
    align-self: center;
    margin-left: 1rem;
    cursor: pointer;
  }

  .edit-permission-group[aria-expanded='true'] {
    @extend .btn-circular;
    padding: 0.5rem;
    margin-left: 0.2rem;
  }
}

.edit-permission-group-user {
  margin-right: 0.3rem;
  padding: 0 0.5rem;
}

.edit-permission-group-user[aria-expanded='true'] {
  @extend .btn-circular;
  padding: 0.5rem;
  margin: -0.5rem 0;
}

.permission-group-history {
  grid-template-columns: 1fr 2fr;
  margin-right: 1rem;

  * {
    align-self: center;
  }

  p {
    font-size: 13px;
    font-weight: bold;
    color: #808080;
  }
}

.floating-message-container {
  position: fixed;
  bottom: $spacer / 3;
  right: 0;
  z-index: 5000;
  width: 30rem;

  .alert {
    // Temporary alert styling
    opacity: 1;
    background-color: $body-color;
    color: $white;
    margin: $spacer / 3 $spacer / 2 0 0;
    padding: 1rem 1.42rem;
    position: relative;
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;

    h4 {
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      align-items: baseline;
    }

    h4,
    p {
      margin-bottom: 0;
    }

    > p {
      margin-top: 1rem;
    }

    i {
      // Font awesome icon
      font-size: 18px;
      margin-right: 1.42rem;
    }

    &.alert-success i {
      color: $success;
    }

    &.alert-danger i {
      color: $danger;
    }

    &.alert-warning i {
      color: $warning;
    }

    .close {
      background: unset;
      border: none;
      font-size: 25px;
      color: white;
      text-shadow: none;
      position: absolute;
      top: 0;
      right: $spacer;
    }
  }
}

.support-button {
  position: fixed;
  bottom: 0.3rem;
  right: 0.35rem;
  background: $blue-800;
  padding: 0.8rem;
  color: $white;
  font-size: 16px;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  cursor: pointer;
}

.support-modal {
  width: 400px;
  border-radius: 10px;

  .card-header {
    border-radius: 10px 10px 0 0;
    align-items: center;
    background: $blue-800;
    color: $white;

    i {
      font-size: 1.2rem;
    }

    .support-modal-header-img {
      height: 40px;
      width: 40px;
      background: $white;
    }
  }

  &-helpdesk {
    background: $blue-200;
    border: solid 1px $gray-300;
    border-radius: 10px;
    column-gap: 10px;

    i.fa-book-reader {
      background: $blue-800;
      color: $white;
    }

    i.fa-arrow-right {
      color: $blue-800;
      align-self: center;
      font-size: 16px;
    }
  }

  &-contact-link {
    color: $blue-800;
  }
}

.form-validation-error-message {
  color: red;
}

.align-right {
  text-align: right;
}

.content-title {
  margin-top: 73px;
}

.info-blob {
  text-align: center;
  text-transform: uppercase;
  color: #ff886e;
  font-size: 0.725em;
  padding: 6px 8px;
  border-radius: 3px;
  background: #fff6df;
}

.font-medium-header {
  font-weight: 300;
  font-size: 1.2em;
}

.draft-info {
  box-shadow: 0px -8px 20px rgba(54, 82, 111, 0.1);
  padding-right: 4em !important;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
}
