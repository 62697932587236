.bookmarkbar-content {
  background-color: #fff;
  border-radius: 4px;
  border: #e1e7ed 1px solid;
  width: 220px;
  padding: 0.5rem;
  i {
    padding: 0.375rem;
    border-radius: 15px;
    &:hover {
      cursor: pointer;
      background-color: #e9ecef;
      border-color: transparent;
      color: #4db894;
    }
  }
}

.bookmark-button-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.bookmark-button-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  input {
    width: 145px;
  }
}

.link-button {
  outline: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  text-align: start;
  user-select: none;
  color: #212529;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid transparent;
  background-clip: padding-box;
  border-radius: 0.375rem;

  width: 100%;

  &:hover {
    background-color: #e9ecef;
    border-color: transparent;
    color: #4db894;
  }

  &:disabled {
    background-color: red;
    color: #6c757d;
    cursor: not-allowed;
  }
}
.bookmark-sidebar {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.bookmark-sidebar-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  i {
    margin-right: 0.313rem;
    margin-top: 0.313rem;
    &:hover {
      cursor: pointer;
    }
  }

  p {
    margin: 0;
  }
}

.bookmark-sidebar-bookmark-content {
  display: flex;
  flex-grow: 1;
  width: 130px;
}

.bookmark-sidebar-content-link {
  display: flex;
  width: 130px;
  flex-direction: row;
  align-items: center;
  gap: 0.313rem;
  justify-content: space-evenly;

  .bookmark-sidebar-content-icon {
    display: flex;
    justify-content: center;
  }
}

.link-bookmark-disabled {
  color: #6c757d;
  cursor: not-allowed !important;
}

.bookmark-sidebar-link-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.bookmark-sidebar-link-content-inner {
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 130px;
  justify-content: space-between;
}
